import React, { useEffect, useState } from 'react'
import { PageBase } from '~/components'
import { Container } from './styles'
import premiumImg from '~/assets/servico-premium.png'
import ModalPremium from '~/components/ModalPremium'
import { useAuth } from '~/hooks'
import { updateLicensedData, getLicensedData } from '~/services/licenseds'
import { updateApp } from '~/services/apps'

const PremiumScreen = () => {
  const { user } = useAuth()
  const [hasPremium, setHasPremium] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const [loading, setLoading] = useState(false) // Estado para controlar o carregamento do botão

  const payNow = async () => {
    try {
      const formData = new FormData()

      formData.append('accountPremium', true)

      // Inicia o estado de loading
      setLoading(true)

      // Chama a função de update, mas não espera a resposta
      await updateApp(formData)

      // Aguarda 3 segundos e recarrega a página
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } catch (error) {
      console.error(error)
      setLoading(false) // Caso ocorra um erro, interrompe o loading
    }
  }

  const onCancel = () => setVisibleModal(false)
  const onOpen = () => setVisibleModal(true)

  useEffect(() => {
    getLicensedData().then(({ data }) => {
      setHasPremium(data?.app?.accountPremium) // Define o estado premium corretamente
    })
  }, [])

  return (
    <PageBase
      content={
        <Container>
          <ModalPremium
            visible={visibleModal}
            label="Você tem certeza?"
            onCancel={onCancel}
            confirmLabel="Sim, confirmar"
            cancelLabel="Não, cancelar"
            onConfirm={() => {}}
            onClose={onCancel}
          />

          {hasPremium ? (
            <div className="premium-container">
              <div className="header">
                <img src={premiumImg} alt="premium" className="premium-image" />
                <h2>Seja Premium</h2>
              </div>

              <div className="separator" />

              {/* Mensagem para usuários com recebimento automático habilitado */}
              <div className="automatic-message">
                <p>
                  Suas conta agora é premium e você fatura no
                  automático! Lembre-se de manter a sua taxa semanal sempre paga
                  para continuar com os pagamentos automáticos.
                </p>
              </div>
            </div>
          ) : (
            <div className="premium-container">
              <div className="header">
                <img src={premiumImg} alt="premium" className="premium-image" />
                <h2>Seja premium</h2>
              </div>

              <div className="separator" />

              <div className="description">
                <p>
                  Tornando sua conta premium, você automátiza tudo no seu app
                  e só espera sua comissão cair na conta.
                </p>
              </div>

              <div className="separator" />

              <div className="benefits">
                <p>
                  <strong>1 - Cobrança Automática:</strong> Garanta a cobrança
                  dos motoristas sem precisar intervir manualmente.
                </p>
                <p>
                  <strong>2 - Rede de motoristas verificados:</strong> Com a nossa
                  rede verificada de motristas parceiros, você não se preocupa
                  com motoristas, nossos motoristas vão antender seus clientes.
                </p>
                <p>
                  <strong>3 - Anúncio no Relep:</strong> Com o Relep, atraia novos
                  passageiros automaticamente por meio de um sistema inteligente
                  que compara apps
                </p>
                <p>
                  <strong>4 - Menos Trabalho Administrativo:</strong> Foque no
                  crescimento do seu negócio enquanto o sistema cuida das
                  cobranças.
                </p>
              </div>

              <div className="price-section">
                <p className="price-title">Apenas:</p>
                <div className="price-container">
                  <p className="price">R$ 27,90 por semana</p>
                </div>
                <p className="discount-note">
                  *Finalize apenas 10 viagens durante a semana e não pague essa
                  taxa! Cancele quando quiser.
                </p>
              </div>

              <div className="button-container">
                <button
                  onClick={payNow}
                  className="pay-now-button"
                  disabled={loading} // Desabilita o botão enquanto está carregando
                >
                  {loading ? 'Carregando...' : 'Quero ser premium'}
                </button>
              </div>
            </div>
          )}
        </Container>
      }
    />
  )
}

export default PremiumScreen
