import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import { useApiEffect } from '~/hooks'
import { getDistrictsNoLimit } from '~/services/districts'

import NButton from '../NButton'
import NSelector from '../NSelector'

import { Container, Form } from './styles'

const NModalAddDistrict = ({ save, label, ...rest }) => {
  const { handleSubmit } = useForm()
  const navigate = useNavigate();
  const [district1, setDistrict1] = useState({ name: '' })
  const [district2, setDistrict2] = useState({ name: '' })
  const [districts, setDistricts] = useState([])

  useApiEffect(getDistrictsNoLimit, (response) => {
    setDistricts(response.data.content)
  })

  const optionsList = districts.filter((district) =>
    district.id !== district1.id && district.id !== district2.id
      ? { name: district.name, value: district.id }
      : null
  )

  const submit = () => {
    if (!district1.id || !district2.id) {
      alert('Preencha todos os campos!')
      return
    }

    save({
      boardingDistrictId: district1.id,
      landingDistrictId: district2.id,
      type: 'LICENSED',
    })
  }

  let stopRefresh;

  function refreshTime () { 
    stopRefresh = setTimeout(refresh, 1000);}

  const refresh = () => {
    window.location.reload()
  }

  return (
    <Container {...rest}>
      <p className="title">Rotas compartilhadas</p>
      <Form onSubmit={handleSubmit(submit)}>
        <NButton
          color="green"
          label="Novo Estabelecimento"
          onClick={() => navigate('/bairros')}
          type="button"
        />
        <NSelector
          label="Embarque:"
          value={district1.name}
          options={optionsList}
          placeholder="Estabelecimento"
          onChange={(data) => setDistrict1(data)}
        />
        <NSelector
          label="Desembarque:"
          value={district2.name}
          options={optionsList}
          placeholder="Personalizado"
          onChange={(data) => setDistrict2(data)}
        />
        <NButton
          color="green"
          className="button"
          label="Confirmar"
          type="submit"
          onClick={() => refreshTime()}
        />
      </Form>
    </Container>
  )
}

export default NModalAddDistrict
