import React from 'react'
import { useForm } from 'react-hook-form'

import NButton from '../NButton'
import NInput from '../NInput'

import { BoundryForm, Container, Form } from './styles'

const NModalAddDistrict = ({ save, label, ...rest }) => {
  const { register, handleSubmit } = useForm()

  const submit = ({ name, city, latitude, longitude, radius }) => {
    const data = {
      name,
      city,
      boundry: {
        latitude,
        longitude,
        radius,
      },
    }

    save(data)
  }

  let stopRefresh;

  function refreshTime () { 
    stopRefresh = setTimeout(refresh, 1000);}

  const refresh = () => {
    window.location.reload()
  }

  return (
    <Container {...rest}>
      <p className="title">Estabelecimentos</p>
      <Form onSubmit={handleSubmit(submit)}>
        <NInput
          label="Nome"
          width="100%"
          placeholder="Nome do estabeleciemento"
          {...register('name', { required: true })}
        />

        <NInput
          label="Cidade:"
          width="100%"
          placeholder="Escreve o nome da cidade..."
          {...register('city', { required: true })}
        />
        <BoundryForm>
          <p>Área de abrangência</p>
          <small>
            Coloque a latitude e longitude do endereço do 
          estabelecimento nesse formato -20.0235414, -43.826377
          </small>
          <NInput
            label="Latitude:"
            width="100%"
            type="number"
            step={0.0000001}
            placeholder="Latitude"
            {...register('latitude', { required: true })}
          />
          <NInput
            label="Longitude:"
            width="100%"
            type="number"
            step={0.0000001}
            placeholder="Longitude"
            {...register('longitude', { required: true })}
          />
          <NInput
            label="Raio:"
            width="100%"
            type="number"
            step="any"
            placeholder="Raio de abrangência"
            {...register('radius', { required: true })}
          />
        </BoundryForm>
        <NButton
          color="green"
          className="button"
          label="Confirmar"
          type="submit"
          onClick={() => refreshTime()}
        />
      </Form>
      {/* <p className="helper">Escreva um ponto de referencia, número etc.</p> */}
    </Container>
  )
}

export default NModalAddDistrict
