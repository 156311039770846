import React from 'react'

import { Container } from './styles'
import { theme } from '~/styles'
import premiumImg from '~/assets/servico-premium.png'

const CardPremium = ({ color }) => {
  return (
    <Container color={color}>
      <div style={{ flex: 5 }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={premiumImg} width={60} height={60} alt="premiumImg" />
          <p
            style={{
              fontSize: 28,
              fontWeight: 700,
              marginLeft: 12,
              marginTop: 6,
            }}
          >
            Seja premium!
          </p>
        </div>
        <p style={{ fontSize: 20, marginTop: 8 }}>
          Tenha acesso a nossa rede de motoristas verificados, anúncio do seu app no app Relep e cobranças automáticas!
        </p>
      </div>

      <div style={{ flex: 2, marginRight: 18, marginLeft: 8 }}>
        <p style={{ fontSize: 50, fontWeight: 'bold' }}>R$ 27,90</p>
        <p style={{ fontSize: 20, color: 'red' }}>Pagamento semanal</p>
      </div>

      <div>
        <a
          href="https://admin.weptek.app/premium"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            height: 70,
            maxWidth: 286,
            backgroundColor: theme.colors.primary,
            fontSize: 20,
            borderRadius: 14,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            textDecoration: 'none',
            padding: '0px 28px',
          }}
        >
          <p style={{ color: '#FFF', fontSize: 20 }}>Assinar</p>
        </a>
      </div>
    </Container>
  )
}
export default CardPremium
